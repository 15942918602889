.problem__tags
  margin: 0 !important
  padding: 0 !important
  list-style: none !important
  font-size: 0
  word-break: keep-all
  display: flex
  flex-wrap: wrap

.problem__tag
  display: inline-block
  vertical-align: top
  height: rem($table-lh)
  margin: rem(0 2px 2px 0) !important
  padding: 0 !important

.problem__tag-item
  padding: 0 10px !important
  margin-left: rem(3px)
  margin-bottom: rem(5px)
  line-height: 30px !important
  &:hover
    text-decoration: none
  > span
    display: flex
    align-items: center

.problem__tag-link
  display: inline-block
  padding: rem(0 7px)
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  max-width: rem(250px)
  line-height: rem($table-lh)
  height: rem($table-lh)
  background: #F0F0F0
  color: $text-1-color !important
  font-size: rem($font-size-small)

  &:hover
    background: $primary-color
    color: #FFF !important
    text-decoration: none

.col--problem-name
  .problem__tags
    float: right

  .problem__tag
    margin: rem(0 2px 0 0) !important
