.page--domain_contest_user
  .col--checkbox
    width: 30px

  .col--user
    width: 90px

  .col--group
    width: 150px

  .col--date
    width: 200px

  .col--title
    width: 160px

  .col--btn
    width: 80px

  .col--btn button
    margin-top: 5px
    margin-bottom: 5px
    display: block
    width: 100%
    height: 35px
    line-height: 35px
    text-align: center
