.page--problem_main
.page--problem_category
  .col--checkbox
    width: rem(61px)

  .col--pid
    width: rem(81px)
  
  .col--status
    width: rem(140px)
    position: relative
    border-right: 1px solid $table-border-color

  .col--difficulty
    width: rem(70px)
    text-align: center

  .col--ac-tried
    width: rem(100px)
    text-align: center
    border-left: 1px solid $table-border-color

  +mobile()
    .col--status,
    .col--tried,
    .col--difficulty
      display: none

    .col--ac
      border-left: 1px solid $table-border-color


.widget--category-filter
  &__category
    margin-bottom: rem(6px)

    &-tag
      display: inline-block
      padding: rem(5px 6px)

      &:hover
        color: #FFF
        background: $primary-color
        text-decoration: none

      &.selected
        color: #FFF
        background: $secondary-color

  &__drop
    position: relative
    font-size: rem($font-size-small)
    width: rem(230px)
    padding: rem(10px)
    margin-right: rem(10px)
    background: $menu-drop-bg-color
    box-shadow: $menu-drop-shadow

    &:before
      content: ''
      display: block
      position: absolute
      width: 0
      height: 0
      border: rem($menu-drop-triangle-size) solid transparent
      border-top-color: #FFF
      border-left-color: #FFF
      box-shadow: $menu-drop-triangle-shadow
      transform: rotate(135deg)
      transform-origin: 0 0
      pointer-events: none
      left: 100%
      top: 50%
      margin-left: rem(floor($menu-drop-triangle-size * 1.414))

  &__subcategory
    margin: rem(2px)
    display: inline-block

    &-tag
      display: inline-block
      padding: rem(5px 6px)

      &:hover
        color: #FFF
        background: $primary-color
        text-decoration: none

      &.selected
        color: #FFF
        background: $secondary-color

.drop-target-attached-right .widget--category-filter__drop
  margin-right: 0
  margin-left: rem(10px)

  &:before
    transform-origin: 100% 0
    transform: rotate(-45deg)
    left: auto
    right: 100%
    margin-left: 0
    margin-right: 0
    margin-top: rem(floor($menu-drop-triangle-size * -1.414))


.display-mode .display-mode-hide,
.edit-mode .edit-mode-hide,
.hide-problem-tag .notag--hide,
.tag--hide
  display: none

.hide-problem-tag .tag--hide
  display: block

.toggle-tag
  float: right

.data-table > tbody > tr
  height: 40.15px

.data-table > thead > tr
  height: 54.9px

.search
  width: 100%
  position: relative
  display: flex

.search > input
  width: 100%
  border: 1px solid #cccccc
  border-right: none
  padding: 5px
  height: 36px
  outline: none
  color: #000000

.search > button
  width: 40px
  height: 36px
  border: 1px solid #00B4CC
  background: #00B4CC
  text-align: center
  color: #fff
  cursor: pointer
  font-size: 20px

.search > div
  position: absolute
  right: 50px
  top: 8px
  font-size: rem(17px)