@import './var.inc.styl'

.select
  form-styles()
  padding-right: 1.1rem
  background-color: $input-background-color
  background-image: url('~vj/misc/icons/' + $icon-expand_more-file)
  background-size: 16px 16px
  background-position: right -1rem center
  background-origin: content-box
  background-repeat: no-repeat
  outline: $input-outline
  transition: outline-color .2s, border-color .2s
  transition-timing-function: ease-out-cubic

  &:focus
    border-color: $input-focus-border-color
    outline: $input-focus-outline
    outline-offset: 0

  &:disabled
    opacity: 0.5

.select-container.compact .select, .select.compact
  margin-bottom: 0
  height: rem($compact-control-height)
  line-height: rem($compact-control-height - 2)
  padding-top: 0
  padding-bottom: 0
