$highlight-button-color = #F6DF45

.page--homework_main
  .homework__title
    line-height: 1.2em
    margin-bottom: rem(10px)
    font-size: rem($font-size-title)

  .homework__info-attended
    color: $success-color

  .homework__date
    margin-right: rem(10px)
    white-space: nowrap