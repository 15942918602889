$stripe-width = 2px

.messagepad__list-item
  display: block
  padding: rem(20px $section-gap-h 20px ($section-gap-h - $stripe-width))
  border-left: $stripe-width solid transparent
  cursor: pointer
  transition: border-color .1s linear, background-color .1s linear
  overflow: hidden
  text-overflow: ellipsis

  &:hover
    border-color: #DDD
    background: #F4F4F4
    text-decoration: none

  &.active
    border-color: $secondary-color
    background: rgba($secondary-color, 0.07)

.messagepad__desc
  font-size: rem($font-size-small)
  color: #888
  max-height: 2em
  margin-top: rem(5px)
  overflow: hidden
  text-overflow: ellipsis
  word-wrap()
