@import './var.inc.styl'

.scratchpad__panel-button
  background: none
  border: 0
  line-height: rem($panel-header-height)
  margin: 0
  color: #FFF
  padding: rem(0 5px)
  cursor: pointer
  font-size: rem($font-size-small)
  outline: 0

  &:hover
    color: $tab-color
