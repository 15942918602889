.immersive-section, .section
  margin-bottom: rem($section-margin)

  +mobile()
    margin-bottom: rem(10px)

  &::before, &::after
    content: ' '
    display: table

  &.side
    font-size: rem($font-size-secondary)

.section
  background: $section-bg-color
  box-shadow: $section-shadow
  transition: transform .5s, opacity .5s
  transition-timing-function: ease-out-cubic

  +mobile()
    box-shadow: none
    margin-left: - rem($grid-padding)
    margin-right: - rem($grid-padding)

.hasjs .section
  +above(rupture.mobile-cutoff)
    transform: translateY(rem(30px))
    opacity: 0

    &.visible
      transform: none
      opacity: 1

.section__header
  padding: rem($section-gap-v) rem($section-gap-h) 0
  position: relative

.section__body
  padding: 0.7em rem($section-gap-h)

  &.no-padding
    padding: 0 !important

.section > .section__body
  &:first-child
    padding-top: rem($section-gap-v)

  &:last-child
    padding-bottom: rem($section-gap-v)

.section__footer
  border-top: 1px solid $table-border-color
  padding: rem($section-gap-h)

.section__title
  color: $section-title-color

.section__header .section__title
  line-height: $form-control-height

h1.section__title
  font-size: 1.4rem
  margin-bottom: rem(12px)

h2.section__title
  font-size: 1.2rem
  margin-bottom: rem(10px)

h3.section__title
  font-size: 1rem
  margin-bottom: rem(8px)

.section__header .section__title
  margin-bottom: 0

.section__tools
  position: absolute
  right: rem($section-gap-h)
  top: rem($section-gap-v)
  font-size: rem($font-size-secondary)

  .tool-button
    appearance: none
    border: 0
    background: none
    color: $section-toolbtn-color
    margin-left: rem(5px)
    padding: rem(0 5px)
    line-height: rem(24px)
    font-size: rem($font-size-small)
    outline: 0

    &:hover
      color: $section-toolbtn-color-hover

.section__list__item
  padding: rem($section-gap-v $section-gap-h)
  border-bottom: 1px solid $table-border-color

  &:last-child
    border-bottom: 0
