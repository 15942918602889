.contest-sidebar__bg
  display: block
  background: #67AABB url(problem-contest-bg.png) right top no-repeat
  color: #FFF !important
  font-size: rem($font-size)
  padding: rem(20px 0)

  +retina()
    background-image: url(problem-contest-bg@2x.png)
    background-size: 131px 150px

  h1
    font-size: rem($font-size-title)
    color: #FFF
    text-shadow: 0 1px 3px rgba(#000, 0.5)

  &:hover
    text-decoration: none

  .contest-sidebar__status
    margin-top: rem(20px)
