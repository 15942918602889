.scratchpad__toolbar
  background: $toolbar-bg
  padding: rem(8px)
  font-size: rem($font-size-small)
  font-weight: normal

.scratchpad__toolbar__item
  margin-right: rem(5px)

  .select
    margin: 0
    height: auto
    line-height: 1

.scratchpad__toolbar__button
  padding: rem(5px)
  font-size: rem($font-size-small)
  font-weight: normal
  background: none
  border: 1px solid transparent
  color: $toolbar-fore
  outline: 0
  transition: .2s linear opacity

  // Hack for Firefox bug #984869
  &>div
    display: flex
    flex-direction: row
    align-items: center

  &.enabled
    cursor: pointer

  .icon
    font-size: rem($font-size-icon)
    margin-right: 2px

  &.enabled:hover
    color: $toolbar-fore-hover
    background: $toolbar-bg-hover

  &.activated
    color: $toolbar-fore-selected !important
    background: $toolbar-bg-selected !important

  &.disabled
    pointer-events: none
    opacity: $toolbar-disabled-opacity

.scratchpad__toolbar__split
  width: 1px
  height: 1rem
  background: #DDD
