$content-color = #eee
$content-second-color = #bdbdbd

$accent-color = #55b6e2

$main-background-color = #121212
$second-background-color = #323334
$hover-background-color = #424242

.theme--dark
  html
    color-scheme: dark

  body
    color: $content-color
    background-color: $main-background-color

  #panel
    background-color: $main-background-color

  .nav
    background-color: $second-background-color

  .nav .nav__item:hover
    background-color: $hover-background-color

  .nav .nav__item
    color: $content-color

  .section
    background-color: $second-background-color
    box-shadow: none

  .footer
    background-color: $second-background-color
    color: $content-color

  h1, h2, h3, h4, h5, h6
    color: $content-color

  a, .link, a:visited, .link:visited, a:hover, .link:hover, a:active, .link:active
    color: $content-second-color

  .typo a:not(.user-profile-name):not(.media-link), .typo .link:not(.user-profile-name):not(.media-link), .typo-a:not(.user-profile-name):not(.media-link), .typo a:visited:not(.user-profile-name):not(.media-link), .typo .link:visited:not(.user-profile-name):not(.media-link), .typo-a:visited:not(.user-profile-name):not(.media-link), .typo a:active:not(.user-profile-name):not(.media-link), .typo .link:active:not(.user-profile-name):not(.media-link), .typo-a:active:not(.user-profile-name):not(.media-link), .typo a:hover:not(.user-profile-name):not(.media-link), .typo .link:hover:not(.user-profile-name):not(.media-link), .typo-a:hover:not(.user-profile-name):not(.media-link)
    color: $accent-color

  .section__title
    color: $content-second-color

  .dropdown .menu
    box-shadow: 0 0.125rem 0.4375rem #3d3d3d
    background-color: rgb(15, 15, 15)

  a.menu__link:not(.disabled):hover, button.menu__link:not(.disabled):hover
    color: #aaa
    background-color: rgb(27, 27, 27)

  blockquote, blockquote.blue, blockquote.red, blockquote.yellow, blockquote.warn, blockquote.note
    background-color: rgb(23, 23, 23)
    color: #999

  blockquote
    border-left-color: #323232

  a.menu__link.active, button.menu__link.active, a.menu__link.active:hover, button.menu__link.active:hover
    background-color: #ed5f82
    color: #fff

  .menu__seperator
    border-top-color: #212121

  .menu__link
    color: #aaa

  .button:not(.codicon)
    background-color: #212121
    border: 1px solid #323232

  .button:not(.codicon),.button:not(.codicon):visited,.button:not(.codicon):hover,.typo .button:not(.codicon),.typo .button:not(.codicon):visited,.typo .button:not(.codicon):hover
    color: #aaa

  .button:not(.codicon):not(.disabled):hover,.button:not(.codicon):not(.disabled):focus
    background-color: #3d3d3d
    border-color: #3d3d3d
    box-shadow: 0 4px 15px rgba(56,56,56,.5)

  .dialog
    background-color: rgba(31, 31, 31, 0.6)

  .dialog.withBg .dialog__content
    background-color: #1f1f1f
    border: 1px solid #323232
    box-shadow: 0 0.125rem 0.625rem rgba(255 255 255 20%)

  .section__table-header
    background-color: transparent
    box-shadow: 0 0.1875rem 0.125rem rgba(255 255 255 3%)

  .data-table tr
    border-top: 1px solid #2b2b2b
    border-bottom: 1px solid #2b2b2b

  .checkbox input
    border-color: #3f3f3f

  .data-table tr:nth-child(even)
    background-color: #1b1b1b

  .col--ac-tried, .col--ac-tried
    border-left-color: #2b2b2b

  .col--status, .col--submit-by, .col--lang, .col--case
    border-right-color: #2b2b2b

  .col--role
    border-left: 1px solid #2b2b2b
    border-left-color: rgb(43, 43, 43)

  .sidebar-user-profile .user-profile-name
    color: #bbb

  .section__tab-header-item:hover
    background-color: #171717

  .sessionlist__item
    border-bottom-color: #323232

  .pager.pager-top
    border-bottom-color: #2b2b2b
    .pager__item
      border-bottom-color: #2b2b2b

  .pager.pager-bottom
    border-top-color: #2b2b2b
    .pager__item
      border-top-color: #2b2b2b

  input
    background-color: #1f1f1f

  .search>input
    border-color: #434343
    border-top-color: rgb(67, 67, 67)
    border-bottom-color: rgb(67, 67, 67)
    border-left-color: rgb(67, 67, 67)
    color: #fff

  .select
    border: 1px solid #323232
    background-color: #1f1f1f

  .select:focus
    border-color: #2960a0
    outline-color: #102f4e

  input.textbox, textarea.textbox
    border: 1px solid #323232
    background-color: #1f1f1f

  .autocomplete-wrapper input
    background-color: #1f1f1f
    color: rgba(255,255,255,.85)

  .autocomplete-wrapper
    border-color: #363636
    background-color: #1f1f1f

  label:not(.quick-input-list-label)
    color: #999

  .scratchpad-container
    background-color: #1f1f1f

  .toolbar
    background-color: rgba(23, 23, 23, 0.8)

  pre[class*=language-]
    border: 1px solid #323232
    background-color: #1f1f1f

  code[class*=language-], pre[class*=language-]
    color: #cbc5c6

  pre[class*=language-]::-moz-selection,pre[class*=language-] ::-moz-selection,code[class*=language-]::-moz-selection,code[class*=language-] ::-moz-selection
    background-color: #ADD6FF26

  pre[class*=language-]::selection,pre[class*=language-] ::selection,code[class*=language-]::selection,code[class*=language-] ::selection
    background-color: #ADD6FF26

  :not(pre)>code[class*=language-]
    background-color: #171717
    border: 1px solid #323232

  .scratchpad__data-input
    border-left-color: #2f2f2f
    background-color: #171717
    color: #999

  pre:not(.vditor-sv), code
    background-color: #171717

  .scratchpad__toolbar
    background-color: #18191d

  .scratchpad__toolbar__button.enabled:hover
    color: #8a94a5
    background-color: #27282c

  .scratchpad__toolbar__split
    background-color: #313131

  .menu__link.highlight
    background: rgb(40, 11, 20)

  .bp4-tag.bp4-minimal:not([class*="bp4-intent-"])
    background-color: rgba(87, 100, 117, .2)
    color: #d9dfe7

  .typo table thead th
    background: #1e1e1e
    background-color: rgb(30, 30, 30)

  .typo table th, .typo table td, .typo table caption
    border-color: #323232
    color: #999

  body>.marker
    box-shadow: 0 2px 7px rgba(255 255 255 30%)
    background-color: #1f1f1f
    border-color: #555

  body>.marker .marker__toolbar
    background-color: #18191d

  .messagepad__sidebar
    border-right-color: #212121

  .messagepad__list-item:hover
    border-color: #323232
    background-color: #1b1b1b

  .messagepad__list-item.active
    border-color: #ed5f82
    background-color: rgba(237,95,130,.07)

  .messagepad__input
    border-top-color: #212121

  .section__tab-container.immersive .section__tab-header-item.selected
    background-color: rgb(15, 15, 15)
    color: #999
    box-shadow: 0 0 10px rgba(255 255 255 30%)

  .dialog--signin__main
    background-color: #1f1f1f
    box-shadow: 0 0 30px rgba(255 255 255 60%)

  .nothing-icon
    filter: brightness(50%)

  .scratchpad__data-input:focus
    background: #1f1f1f
    border-color: #1c2d40

  .section__list__item
    border-bottom-color: #2b2b2b

  .autocomplete-list
    background-color: #1f1f1f
    li[aria-selected=true]
      background-color: #151515
    li[data-focus=true]
      background-color: #101829
      cursor: pointer

  .dczcomments__item, .dczcomments__reply
    border-top-color: #2b2b2b

  .autocomplete-tag
    background-color: #ff0000

  .problem__tag-link
    background-color: #1f1f1f
    color: #aaa!important

  label.textbox.material input
    border-color: #333
    color: #aaa
    border-bottom-color: rgba(170,170,170,.4)

  .widget--category-filter__drop
    background-color: #1f1f1f
    box-shadow: 0 0.125rem 0.4375rem rgba(255 255 255 30%)

  hr
    border-bottom-color: #404040

  .section__footer
    border-top-color: #2b2b2b

  .data-table thead>tr
    border-bottom-color: #2b2b2b

  blockquote.note
    border-left-color: #29679e
    &:before
      color: #29679e

  blockquote.warn
    border-left-color: #a80800
    &:before
      color: #a80800
    
  blockquote.red
    border-left-color: #a80800

  blockquote.yellow
    border-left-color: #8f7c00

  blockquote.blue
    border-left-color: #00396b

  .token.comment,.token.prolog,.token.doctype,.token.cdata
    color: #57A64A
    font-style: italic

  .token.namespace
    opacity: .7

  .token.string
    color: #D69D85

  .token.punctuation,.token.operator
    color: #B4B4B4

  .token.url,.token.symbol,.token.number,.token.boolean,.token.variable,.token.constant,.token.inserted
    color: #B5CEA8

  .token.atrule,.token.keyword,.token.attr-value,.language-autohotkey .token.selector,.language-json .token.boolean,.language-json .token.number,code[class*=language-css]
    color: #569cd6

  .token.function
    color: #C8C8C8

  .token.deleted,.language-autohotkey .token.tag
    color: #9a050f

  .token.selector,.language-autohotkey .token.keyword
    color: #00009f

  .token.important
    color: #e90

  .token.important,.token.bold
    font-weight: 700

  .token.italic
    font-style: italic

  .token.class-name,.language-json .token.property
    color: #4EC9B0

  .token.tag,.token.selector
    color: #d7ba7d

  .token.attr-name,.token.property,.token.regex,.token.entity
    color: #d16969

  .token.directive.tag .tag
    background: #ffff00
    color: #393a34

  @media only screen and (max-width: 600px) {
    .footer__category {
      border-bottom-color: #1d1d1d
    }
  }
