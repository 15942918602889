.pager
  text-align: center
  font-size: rem($font-size-small)

  &:empty
    display: none
  
  &.pager-bottom
    margin-bottom: rem(10px)
    border-top: 2px solid $pager-border-color

    .pager__item
      border-top: 2px solid $pager-border-color

  &.pager-top
    margin-top: rem(10px)
    border-bottom: 2px solid $pager-border-color

    .pager__item
      border-bottom: 2px solid $pager-border-color

  li
    margin: 0
    display: inline-block
    margin-top: -2px

.pager__item
  display: inline-block
  padding: rem(10px)
  text-decoration: none
  border: 0
  transition: border-top .2s linear, color .2s linear
  color: #a7a7a7

  &.link
    &, .typo &
      color: #a7a7a7

    &:hover
      border-color: $primary-color
      text-decoration: none
      &, .typo &
        color: #666

  &.current
    color: $secondary-color !important
    border-color: $secondary-color !important

+mobile()
  .pager__item
    &.ellipsis,
    &.first,
    &.previous,
    &.next,
    &.last
      display: none

