.ScratchpadMonacoEditor
  flex: 1
  position: relative

.cm-s-vjcm
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  font-family: $code-font-family
  font-size: rem($font-size-secondary)
  line-height: 1.2
