.user-profile-avatar
  border-radius: 50% !important

.user-profile-badge
  display: inline-block !important
  font-size: rem(12px) !important
  padding: rem(3px 4px) !important
  line-height: 1 !important

  &:hover
    text-decoration: none !important

for n in (1..21)
  .user-profile-bg--{n}
    background-image: url('backgrounds/' + n + '.jpg')

  .user-profile-bg--thumbnail-{n}
    background-image: url('backgrounds/thumbnail/' + n + '.jpg')

for key, _ in $badge-bg-color
  .badge--{key}
    background-color: $badge-bg-color[key] !important
    color: $badge-text-color[key] !important
