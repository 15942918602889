.page--record_main
  .col--status
    width: rem(170px)
    position: relative

  .col--submit-at
    width: rem(115px)
    text-align: center

  &.nojs .col--submit-at
    width: rem(160px)

  .col--submit-by
    width: rem(140px)
    text-align: right

  .col--time, .col--memory, .col--lang
    width: rem(95px)
    text-align: center

  .col--status, .col--submit-by, .col--lang
    border-right: 1px solid $table-border-color

  .col--status__text
    position: relative
    z-index: 2

  .col--status__progress-container
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 1

  .col--status__progress
    position: absolute
    left: 0
    top: 0
    height: 100%
    background: #fff6d1

  +tablet()
    .col--time,
    .col--memory,
    .col--lang
      display: none

  +mobile()
    .col--submit-by,
    .col--submit-at,
    .col--time,
    .col--memory,
    .col--lang
      display: none
