.rotator
  position: relative
  height: 1em

.rotator__item
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  transition: transform .2s, opacity .2s
  transition-timing-function: ease-out-cubic

  &.pos--above
    transform: translateY(-100%)
    opacity: 0

  &.pos--below
    transform: translateY(100%)
    opacity: 0
