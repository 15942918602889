form-styles()
  appearance: none
  display: block
  width: 100%
  font-size: rem($font-size-secondary)
  margin: $input-margin
  height: rem($form-control-height)
  line-height: 1.2
  padding: rem(5px)
  border: $input-border

  &.inline
    display: inline-block
    width: auto
