.page--training_main
  .training__title
    line-height: 1.2
    margin-bottom: 0.4em
    font-size: rem($font-size-title)

  .training__intro
    color: lighten($text-1-color, 20%)
    font-size: rem($font-size-secondary)
    margin: 1em 0

  .training__item
    padding-left: rem(5px)

  .training__participants
    width: rem(80px)

  .training__progress
    line-height: rem(17px)
    vertical-align: top

  .training__progress-bar
    display: inline-block
    width: rem(100px)
    height: rem(5px)
    position: relative
    background: #EEE
    margin: rem(6px 0)
    line-height: 1
    vertical-align: top

  .training__progress-track
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 0%
    background-color: green

  .secondary.training__list
    .training__item
      margin-bottom: rem(15px)
    .training__title
      font-size: rem($font-size)

    &.my
      .training-status--icon
        font-size: rem($font-size-icon * 1.2)
