.nothing-placeholder
  padding: rem(80px 0)
  text-align: center
  font-size: rem($font-size-title)
  color: #AAA

  &.compact
    padding: rem(20px 0)

.nothing-icon
  background: url(./puzzled_twd2.svg) no-repeat center center
  height: 184px
  margin-bottom: rem(20px)
