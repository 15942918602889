.page--contest_detail
  .col--status
    width: rem(150px)

  .col--submit-at
    width: rem(150px)

  .col--problem
    border-left: 1px solid $table-border-color

  +mobile()
    .col--submit-at
      display: none
