$footer-extra-link-gap = 20px

.footer
  background: $footer-bg-color
  color: $text-1-color

  h1
    font-size: rem($font-size-title)

    +mobile()
      padding: rem($grid-padding)
      cursor: pointer
      font-size: rem($font-size-secondary)

.footer__category
  padding-top: rem(30px)
  padding-bottom: rem(50px)

  +mobile()
    padding-top: rem($grid-padding)
    padding-bottom: rem($grid-padding)
    border-bottom: 1px solid darken($footer-bg-color, 5%)

    &.expandable
      padding: 0

  .expand-icon
    display: none

    +mobile()
      display: inline-block

  &.expanded .expand-icon
    transform: rotate(180deg)

.footer__category__list
  margin-top: rem(10px)
  margin-bottom: rem(10px)
  font-size: rem($font-size-small)
  line-height: 2

  +mobile()
    margin-top: 0
    padding-left: rem($grid-padding)
    padding-right: rem($grid-padding)

.footer__category__expander
  +mobile()
    display: none

  +above(rupture.mobile-cutoff)
    display: block !important

.footer__extra-left
  float: left

.footer__extra-right
  float: right

.footer__extra-link
  padding: rem(20px 0)
  font-size: rem($font-size-small)

.footer__extra-link-item
  float: left
  line-height: 2

.footer__extra-left .footer__extra-link-item
  margin-right: rem($footer-extra-link-gap)

.footer__extra-right .footer__extra-link-item
  margin-left: rem($footer-extra-link-gap)

.layout--immersive
  .footer
    background: none
    color: #FFF

    a, a:visited, a:active
      color: #FFF

    a:hover
      color: #FFF

+mobile()
  .footer__extra-left, .footer__extra-right
    float: none
    display: block

    .footer__extra-link-item
      float: none
      display: inline-block
      margin-left: 0
      margin-right: $footer-extra-link-gap
