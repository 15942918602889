@import './var.inc.styl'

$checkbox-size = 16px

.checkbox
  font-size: rem($font-size-secondary)
  line-height: 1.4
  border: 0
  display: inline-block
  vertical-align: middle
  cursor: pointer
  user-select: none
  color: lighten($text-1-color, 30%)
  margin: $input-margin
  padding: ((rem($form-control-height) - (@line-height * @font-size)) / 2) 0

  &:hover
    color: $text-1-color

  input
    appearance: none
    border: 1px solid #D0D0D0
    display: inline-block
    border-radius: 1px
    vertical-align: top
    width: rem($checkbox-size)
    height: rem($checkbox-size)
    margin: ((@line-height * @font-size - rem($checkbox-size)) / 2) 0
    margin-right: rem(5px)
    position: relative
    outline: 0
    transition: background .1s linear
    background: none
    cursor: pointer

    &:after
      content: ''
      width: rem(10px)
      height: rem(6px)
      position: absolute
      top: rem(3px)
      left: rem(2px)
      border: 2px solid #FFF
      border-top: none
      border-right: none
      background: transparent
      transform: rotate(-65deg) scale(0.5)
      opacity: 0

    &:enabled:hover
      border-color: rgba($secondary-color, 0.7)

    &:checked
      background: $secondary-color
      border-color: $secondary-color

    &:checked:after
      opacity: 1
      transform: rotate(-45deg)

    &:disabled
      opacity: 0.5

  &.compact
    margin-bottom: 0
    line-height: 1.2
    padding: ((rem($compact-control-height) - (@line-height * @font-size)) / 2) 0

    input
      margin: ((@line-height * @font-size - rem($checkbox-size)) / 2) 0
      margin-right: rem(5px)

label.checkbox.inverse
  color: rgba($immersive-text-color, 0.8)

  input
    color: $immersive-text-color
    border-color: rgba($immersive-text-color, 0.4)

    &:disabled, &:read-only
      color: rgba($immersive-text-color, 0.6)

    &:enabled:read-write:hover
      border-color: rgba($immersive-text-color, 0.8)

    &:enabled:read-write:focus
      border-color: $immersive-primary-color

  &.focus
    color: $immersive-primary-color
