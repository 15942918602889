// extracted from:
// https://github.com/jonsuh/hamburgers/blob/master/dist/hamburgers.css
// copyright @jonsuh
.hamburger
  padding: 0px 0px
  display: inline-block
  cursor: pointer
  transition-property: opacity, -webkit-filter
  transition-property: opacity, filter
  transition-property: opacity, filter, -webkit-filter
  transition-duration: 0.15s
  transition-timing-function: linear
  font: inherit
  color: inherit
  text-transform: none
  background-color: transparent
  border: 0
  margin: 0
  overflow: visible

.hamburger:hover
  opacity: 0.7

.hamburger-box
  width: rem(18px)
  height: rem(12px)
  display: inline-block
  position: relative

.hamburger-inner
  display: block
  top: 50%
  margin-top: rem(-1px)

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after
  width: rem(18px)
  height: rem(2px)
  background-color: #FFF
  border-radius: 1px
  position: absolute
  transition-property: transform
  transition-duration: 0.15s
  transition-timing-function: ease

.layout--immersive
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after
    background-color: #FFF

.hamburger-inner::before,
.hamburger-inner::after
  content: ""
  display: block

.hamburger-inner::before
  top: rem(-5px)

.hamburger-inner::after
  bottom: rem(-5px)

.hamburger--spin .hamburger-inner
  transition-duration: 0.3s
  transition-timing-function: ease-out-cubic

.hamburger--spin .hamburger-inner::before
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in

.hamburger--spin .hamburger-inner::after
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s ease-in-cubic

.hamburger--spin.is-active .hamburger-inner
  transform: rotate(225deg)
  transition-delay: 0.14s
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

.hamburger--spin.is-active .hamburger-inner::before
  top: 0
  opacity: 0
  transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out

.hamburger--spin.is-active .hamburger-inner::after
  bottom: 0
  transform: rotate(-90deg)
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s ease-out-cubic
