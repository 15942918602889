$list-height = 500px
$sidebar-width = 250px
$input-height = 80px
$input-padding = 10px
$send-button-width = 50px

#messagePad
  min-height: rem($list-height)
  position: relative

.messagepad
  position: relative

.messagepad__sidebar
  border-right: 1px solid #EEE
  float: left
  width: rem($sidebar-width)

.messagepad__list
  overflow: auto
  height: rem($list-height)

.messagepad__content
  position: absolute
  left: rem($sidebar-width)
  right: 0
  top: 0
  bottom: rem($input-height)
  overflow: auto
  padding: rem(30px)

.messagepad__input
  display: none
  position: absolute
  left: rem($sidebar-width)
  right: 0
  bottom: 0
  height: $input-height
  border-top: 1px solid #EEE

  &.visible
    display: block

  button
    display: block
    appearance: none
    position: absolute
    right: 0
    width: rem($send-button-width)
    top: 0
    height: 100%
    border: 0
    background: none
    text-align: center
    line-height: rem($input-height)
    font-size: rem($font-size-icon)
    transition: background-color .1s, color .1s, opacity .1s
    transition-timing-function: linear
    outline: 0

    &:enabled
      cursor: pointer
      background-color: $primary-color
      color: #FFF

      &:hover
        background-color: lighten($primary-color, 10%)

    &:disabled
      color: $text-1-color
      opacity: 0.3

.messagepad__textarea-container
  position: absolute
  left: rem($input-padding)
  right: rem($send-button-width + $input-padding)
  top: rem($input-padding)
  bottom: rem($input-padding)

  textarea
    display: block
    border: 0
    background: none
    padding: 0
    width: 100%
    height: 100%
    font-size: rem($font-size-secondary)
    resize: none
    outline: 0
