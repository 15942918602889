.vditor
  margin-bottom: rem(10px)

.vditor-preview__action
  display: none

.vditor ol
  list-style: decimal

.decoration-hide
  color: grey !important
  background-color: grey !important
