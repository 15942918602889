$header-gap-mini = ($header-bg-height-mini - $nav-item-height - $header-logo-height) / 2
$header-gap-mini-domain = ($header-bg-height-mini - $nav-item-height - $header-logo-domain-lh - $header-logo-system-lh) / 2

.global-notification
  padding: rem(20px 0)
  background: #FFF

  &.warning
    background: #ffe1a6

.header__logo
  display: block
  width: rem($header-logo-width-real)
  height: rem($header-logo-height + $header-gap-mini + $nav-item-height)
  background: no-repeat
  background-size: rem($header-logo-width-real) rem($header-logo-height-real)
  background-position: 0 rem($header-gap-mini + $nav-item-height - ($header-logo-height-real - $header-logo-height))
  transition: transform .5s, opacity .5s
  transition-timing-function: ease-out-cubic
  transform-origin: 0 0
  opacity: 1
  +mobile()
    display: inline-block

.header--collapsed

  #panel
    padding-top: $header-bg-height

  .header__logo
    transform: scale(0)
    opacity: 0

.location
  position: absolute
  left: 0
  bottom: 0
  width: 100%
  height: rem($header-layer-height)
  background: rgba(#000, 0.5)

  +mobile()
    position: relative
    height: auto

  .header__hamburger
    display: none

    +mobile()
      display: block

.header--mobile
  display: none

.header--mobile, .location
  +mobile()
    display: block
    background: #56758f
    color: #FFF
    line-height: rem($nav-item-height)
    font-size: rem($font-size-title)
    padding: rem(10px 0)

  a, a:visited, a:hover
    color: #FFF

.header--mobile__domain
  display: block
  width: $nav-logo-width    // no rem
  height: $nav-logo-height  // no rem
  margin-top: rem(10px)
  +retina()
    background-size: $nav-logo-width $nav-logo-height // no rem

.header__hamburger
  background: none
  border: 0
  border-radius: 0
  outline: 0
