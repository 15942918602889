@css {
span.cm-comment { color: #008000; }
span.cm-keyword, span.cm-variable-3 { line-height: 1em; color: #00f; }
span.cm-string { color: #a31515; }
span.cm-builtin { line-height: 1em; font-weight: bold; color: #077; }
span.cm-special { line-height: 1em; font-weight: bold; color: #0aa; }
span.cm-variable { color: black; }
span.cm-meta { color: #2b91af; }
span.cm-link { color: #3a3; }
}
