.graphiql-container .doc-explorer-title, .graphiql-container .history-title
  overflow: hidden;
  padding: 5px 40px 0px 0px !important

.graphiql-container .docExplorerWrap
  font-family: var(--code-font-family) !important

.graphiql-container .doc-explorer-back
  overflow: hidden;
  padding: 8px 20px 0px 20px !important
