.page--discussion_detail,
.page--blog_detail

  .topic__content
    margin: rem(20px 0)

  .profile__bg
    background-size: cover
    background-position: center center
    height: rem(60px)
    margin-bottom: rem(-32px)

  .sidebar-user-profile
    .media__body
      padding-left: rem(10px)
      padding-top: rem(20px)

    .user-profile-name
      font-size: 1.2rem
      color: #444
      vertical-align: middle
