.page--domain_permission
  .col--p
    width: 80px
    text-align: center
    border-left: 1px solid $table-border-color

  td.col--description
    padding-left: rem(30px) !important

  .col--family
    line-height: 2
    color: #FFF
    background: lighten(saturate($primary-color, 70%), 30%)
    border-top: 2px solid $primary-color
