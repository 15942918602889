html
  height: 100%

body
  width: 100%

#panel
  display: flex
  flex-direction: column

body, #panel
  min-height: 100%
  min-height: 100vh

.slideout-panel
  position: relative
  z-index: 1

.slideout-overlay
  display: none
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 99999

+mobile()
  .nav.slideout-menu
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: auto
    z-index: 0
    width: 200px
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    display: none
    height: auto
    min-height: 100vh

  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel
    overflow: hidden

  .slideout-open .slideout-menu
    display: block !important
