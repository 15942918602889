.page--homework_scoreboard
  .col--rank
    width: rem(60px)
    text-align: center
    border-right: 1px solid $table-border-color

  .col--user
    min-width: rem(150px)

  .col--total_score, .col--time
    width: rem(100px)
    text-align: center

  .col--problem, .col--record
    width: rem(100px)
    border-left: 1px solid $table-border-color
    text-align: center

  +mobile()
    .col--problem, .col--record
      display: none
