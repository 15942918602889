.drop-element,
.drop-element:after,
.drop-element:before,
.drop-element *,
.drop-element *:after,
.drop-element *:before
  box-sizing: border-box

.drop-element
  position: absolute
  display: none
  opacity: 0
  transition: opacity .1s ease-in-out
  z-index: 1000   // managed

  &.drop-open
    display: block

  &.drop-open-transitionend
    display: block

  &.drop-after-open
    opacity: 1

.drop-target-attached-top.drop-target-attached-left .drop-content
  transform-origin: -10% 110%
.drop-target-attached-top.drop-target-attached-center .drop-content
  transform-origin: 50% 110%
.drop-target-attached-top.drop-target-attached-right .drop-content
  transform-origin: 110% 110%
.drop-target-attached-bottom.drop-target-attached-left .drop-content
  transform-origin: -10% -10%
.drop-target-attached-bottom.drop-target-attached-center .drop-content
  transform-origin: 50% -10%
.drop-target-attached-bottom.drop-target-attached-right .drop-content
  transform-origin: 110% -10%
.drop-target-attached-center.drop-target-attached-right .drop-content
  transform-origin: -10% 50%
.drop-target-attached-center.drop-target-attached-left .drop-content
  transform-origin: 110% 50%
