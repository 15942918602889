// @import '~vj/components/tab/var.inc.styl'

.page--user_register
  #btnSendVer
    margin: 25px 0 0 0

  .err-tips
    display: inline-block
    color: red
    margin: 0 24px
    display: none