.page--ranking, .page--homepage
  .col--rank
    width: rem(65px)
    position: relative

  .col--user
    width: rem(220px)

  .col--rp
    width: rem(60px)
    text-align: right

  .col--detail
    width: rem(50px)
    text-align: center

  .col--ac
    width: rem(50px)
    text-align: center

  .col--bio
    display: block;

  td.col--bio
    height: 36px !important;

  +mobile()
    .col--bio, .col--detail
      display: none
