.page--status
  .col--id
    width: 100px

  .col--os
    width: 200px

  .col--status
    width: 130px

  .col--memory
    width: 150px

  .col--request
    width: 130px

  +mobile()
    .col--os
    .col--cpu
      display: none