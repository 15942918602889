.float-left
  float: left

.float-right
  float: right

.clearfix:after
  visibility: hidden
  display: block
  font-size: 0
  content: ' '
  clear: both
  height: 0
  zoom: 1
