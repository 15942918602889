.page--discussion_main,
.page--discussion_node,
.page--blog_main,
.page--user_detail
  .discussion__item
    border-left: rem(5px) solid transparent
    padding-left: rem(5px)

    &.highlight
      border-left: rem(5px) solid $highlight-color

  .discussion__title
    font-size: rem($font-size-title)
    line-height: 1.2em
    margin-bottom: rem(10px)

    a:visited
      color: lighten($text-1-color, 60%)

  .discussion__replies
    width: rem(70px)

  .discussion-node__bg
    height: 0
    box-sizing: content-box
    padding-bottom: 40%
    background-size: cover
    background-position: left center
    background-repeat: no-repeat

    for id in 'qa' 'share' 'vijos' 'solution' 'advice'
      &.{id}
        background-image: url('./discussion_node_bg/nodes_' + id + '.png')
        +retina()
          background-image: url('./discussion_node_bg/nodes@2x_' + id + '.png')

    +below(rupture.desktop-cutoff)
      display: none
