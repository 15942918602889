$spinner-size = 18px
$spinner-margin = ($nav-item-height - $spinner-size) / 2

#nprogress
  pointer-events: none

#nprogress .bar
  background: $nprogress-color
  position: fixed
  top: $nav-item-height
  z-index: 99
  left: 0
  width: 100%
  height: 2px

#nprogress .peg
  display: none

#nprogress .spinner
  display: block
  position: fixed
  z-index: 99999
  top: rem($spinner-margin)
  right: rem($spinner-margin)

#nprogress .spinner-icon
  width: rem($spinner-size)
  height: rem($spinner-size)
  box-sizing: border-box
  border: solid 2px transparent
  border-top-color: $nprogress-color
  border-left-color: $nprogress-color
  border-radius: 50%
  animation: nprogress-spinner 400ms linear infinite

.nprogress-custom-parent
  overflow: hidden
  position: relative

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar
  position: absolute

@keyframes nprogress-spinner
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
