.picker__holder
  margin-top: -1rem
  border-radius: 0
  transform: scale(0.9) translateZ(0)
  transform-origin: 0 0
  outline: 0
  font-size: rem($font-size-secondary)
  transition: transform .1s ease-in-out,
              opacity .1s ease-in-out,
              max-height 0s .1s,
              border-width 0s .1s

.picker--opened .picker__holder
  transform: scale(1) translateZ(0)
  transition: transform .1s ease-in-out,
              opacity .1s ease-in-out,
              max-height 0s,
              border-width 0s
  box-shadow: $data-picker-shadow

.picker__list
  padding: 0

.picker__list-item
  border: 0

.picker__list-item:hover
  background: $datapicker-list-item-bg-color-hover

.picker__list-item--selected, .picker__list-item--selected:hover, .picker--focused .picker__list-item--selected
  background: $primary-color

.picker__day--infocus:hover, .picker__day--outfocus:hover
  background: $datapicker-list-item-bg-color-hover

.picker__day--highlighted
  border-color: transparent

.picker__day--highlighted:hover, .picker--focused .picker__day--highlighted
  background: transparent

.picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected
  background: $primary-color

.picker__button--today:hover, .picker__button--clear:hover, .picker__button--close:hover
  background: $datapicker-list-item-bg-color-hover
  border-color: $datapicker-list-item-bg-color-hover

.picker__button--today:focus, .picker__button--clear:focus, .picker__button--close:focus
  background: $datapicker-list-item-bg-color-hover
  border-color: $datapicker-list-item-bg-color-hover

.picker__nav--prev:hover, .picker__nav--next:hover
  background: $datapicker-list-item-bg-color-hover
