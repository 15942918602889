.contest-type-tag
.contest-tag
.contest-tag-rated
  font-size: rem($font-size-small)
  display: inline-block
  line-height: rem(22px)
  padding: rem(0 10px)
  vertical-align: middle
  border-radius: rem(12px)

  &:hover
    text-decoration: none
    color: #FFF !important

.contest-tag-rated
  background: orange
  color: #FFF

  .icon
    margin-right: rem(5px)

.contest-type-tag
  &, &:visited
    &, .supplementary &
      color: #FFF

  .icon
    margin-right: rem(5px)

for key, value in $contest-color
  .contest-type--{key} .contest-type-tag
    background: value

    &:hover
      background: lighten(value, 20%)

.contest-tag
  border: 1px solid $supplementary-border-color

  &:hover
    background: $primary-color
    border-color: $primary-color
