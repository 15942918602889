.discussion-node-tag
  font-size: rem($font-size-small)
  display: inline-block
  line-height: rem(22px)
  padding: rem(0 10px)
  vertical-align: middle
  border-radius: rem(12px)
  border: 1px solid $supplementary-border-color

  &:hover
    background: $primary-color
    color: #FFF !important
    text-decoration: none
    border-color: $primary-color

  .icon
    margin-right: rem(5px)
