.menu
  padding: rem(5px 0)
  font-size: rem($font-size-secondary)

  .icon
    font-size: rem($font-size-icon)
    display: inline-block
    margin-right: rem(5px)
    line-height: rem(18px)
    vertical-align: top

  .hasjs &.collapsed
    display: none

.menu__item .menu
  padding: 0

.menu__link
  outline: 0
  text-align: left
  display: block
  width: 100%
  padding: rem(12px $section-gap-h)
  line-height: rem(18px)
  color: $text-1-color
  border-left: 3px solid transparent
  position: relative

  &.highlight
    background: mix($highlight-color, #FFF, 10%)

  &.expandable:after
    font-family: $icon-font-name
    font-size: rem($font-size-icon)
    content: $icon-expand_more
    position: absolute
    right: $section-gap-h
    top: 50%
    line-height: 1
    margin-top: rem($font-size-icon / -2)
    transition: transform .2s ease-out-cubic
    opacity: 0.85

a.menu__link, button.menu__link
  &.half
    padding-right: 0
    width: 50%
  &.disabled
    cursor: default
    opacity: 0.5
  &:hover
    text-decoration: none
  &:not(.disabled):hover
    color: $text-1-color
    background: $menu-item-bg-color-hover
    border-color: darken($primary-color, 10%)
  &.active, &.active:hover
    border-color: darken($secondary-color, 30%)
    background: $secondary-color
    color: #FFF

.menu__item .menu .menu__link
  padding-left: rem($section-gap-h + 10px)

.menu__seperator
  height: 1px
  border-top: 1px solid #EEE
  margin: rem(5px 0)
