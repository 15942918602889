.page--home_domain
  .col--icon
    width: 60px
    line-height: 1

    img
      border-radius: 50%

  .col--role
    width: rem(160px)
    border-left: 1px solid $table-border-color

  .col--action
    width: rem(135px)

  +mobile()
    .col--icon, .col--role
      display: none
