$error_width = 650px
$twd2_width = 270px

.page--error
  .error__icon-container,
  .error__text-container
    text-align: center
    margin: rem(20px 0)

  .error__text-container
    color: #777
    line-height: 1.5

    h1
      font-size: 4rem
      color: #999
      margin-bottom: rem(20px)

    li
      list-style: decimal inside

  .error__twd2
    background: url('error/twd2.svg') no-repeat
    width: $twd2_width
    height: $twd2_width
    display: inline-block

  +above(rupture.mobile-cutoff)
    .error__container
      width: rem(700px)
      margin: rem(50px) auto
      position: relative

    .error__icon-container
      float: left
      width: $twd2_width

    .error__text-container
      left: rem($twd2_width + 50px)
      position: absolute
      top: 50%
      transform: translateY(-50%)
      text-align: left
      margin: 0
