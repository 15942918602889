.autocomplete-dummy
  display: none !important

.autocomplete-wrapper
  width: 300px
  border: 1px solid #d9d9d9
  background-color: #fff
  padding: 1px
  display: flex
  flex-wrap: wrap
  overflow-y: auto

  &:hover
    border-color: #40a9ff

  &.focused
    border-color: #40a9ff
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2)

  input
    background-color: #fff
    color: rgba(0,0,0,.85)
    height: 30px
    box-sizing: border-box
    padding: 4px 6px
    width: 0
    min-width: 30px
    flex-grow: 1
    border: 0
    margin: 0
    outline: 0

.autocomplete-tag
  display: flex
  align-items: center
  height: 24px
  margin: 2px
  line-height: 22px
  background-color: #fafafa
  border: 1px solid #e8e8e8
  border-radius: 2px
  box-sizing: content-box
  padding: 0 4px 0 10px
  outline: 0
  overflow: hidden

  &:focus
    border-color: #40a9ff
    background-color: #e6f7ff

  &>div
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &>span.bp4-icon>svg
    font-size: 12px
    cursor: pointer
    padding: 4px

.autocomplete-list
  min-width: 300px
  margin: 2px 0 0
  padding: 0
  position: absolute
  list-style: none
  background-color: #fff
  overflow: auto
  max-height: 500px
  border-radius: 4px
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15)
  z-index: 999

  li
    padding: 5px 12px
    display: flex

    &>div
      flex-grow: 1

    &>span.bp4-icon>svg
      color: transparent

    &[aria-selected='true']
      background-color: #fafafa
      font-weight: 600

      &>span.bp4-icon>svg
        color: #1890ff

    &[data-focus='true']
      background-color: #e6f7ff
      cursor: pointer

      &>span.bp4-icon>svg
        color: currentColor
