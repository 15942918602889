$highlight-button-color = #F6DF45

.page--contest_main
  .contest__title
    font-size: rem($font-size-title)
    line-height: 1.2em
    margin-bottom: rem(10px)

  .contest__date
    margin-right: rem(10px)
    white-space: nowrap

  .contest__info-attended
    color: $success-color

  .contest__item
    border-left: rem(5px) solid transparent
    padding-left: rem(5px)
    
    &.live
      border-left: rem(5px) solid #E98696

    &.ready
      border-left: rem(5px) solid #469DCF

  .section--contest
    &.live
      background: #E98696
      background-image: -webkit-radial-gradient(left top, ellipse farthest-corner, #E98696 0%, #E96B6B 100%)

    &.ready
      background: #469DCF
      background-image: -webkit-radial-gradient(left top, ellipse farthest-corner, #8ACDE6 0%, #469DCF 100%)

    .status_title
      display: inline-block
      font-size: rem($font-size-secondary)
      color: #FFF
      background: rgba(#000, 0.3)
      padding: rem(7px 10px)
      border-radius: rem(2px)

    h1
      line-height: 1.2
      font-size: rem(25px)
      margin: rem(20px 0)

    .info
      color: #FFF
      font-size: rem($font-size-secondary)

      .icon
        color: #FFEB81
        font-size: rem($font-size-icon)

      li
        display: inline-block
        margin-right: rem(10px)

    .attandance
      margin-top: rem(15px)
      font-size: rem($font-size-secondary)
      opacity: 0.8

    .detail-button
      cursor: pointer
      display: block
      text-align: center
      background: $highlight-button-color
      color: darken($highlight-button-color, 70%)
      line-height: rem(40px)
      border-radius: rem(20px)
      width: rem(150px)
      position: relative
      overflow: hidden
      transition: box-shadow .4s, background-color .4s
      transition-timing-function: ease-out-cubic

      &:after
        position: absolute
        background: #fff
        content: ""
        height: rem(100px)
        left: rem(-70px)
        top: rem(-25px)
        width: rem(50px)
        opacity: 0.2
        transform: rotate(35deg)
        transition: left 0.6s ease-out-cubic
        z-index: 1

      &:hover
        text-decoration: none
        box-shadow: 0 2px 20px rgba($highlight-button-color, 0.8)
        background: lighten($highlight-button-color, 20%)

        &:after
          left: 150%

    +mobile()
      .media, .media_body, .media__right
        display: block
        padding: 0

      .media__right .section__body
        padding-top: 0

      .detail-button
        width: auto
