.page--problem_detail,
.page--contest_detail_problem,
.page--homework_detail_problem
  .section__tab-header
    padding-top: 0;
  
  .section__tab-container  + .section__body
    padding-top: 0
  
  .section__tab-header-item
    border-top: 3px solid transparent;
    transition: border-color 0.2s, background 0.2s, color 0.2s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    margin-right: 0;
    color: #182026 !important;
    text-decoration: none;

    &:hover
      border-color: #5f9fd6;
      background: #f8f8f8;
    
    &.tab--active
      border-color: #ed5f82;