@import 'prism-themes/themes/prism-vs.css'

pre, code
  background: $syntax-hl-bg-color

pre > code
  background: none
  line-height: 1.5
  color: black
  text-align: left
  white-space: pre-wrap
  word-spacing: normal
  word-break: normal
  tab-size: 4
  hyphens: none

pre
  position: relative
  padding: rem(5px)

code
  color: $code-color
  font-size: rem($font-size-secondary)

[data-syntax-hl-show-line-number] pre.line-numbers > code
  white-space: pre

.sample > .toolbar
  right: 0.9375em !important
  top: 3.7875em !important

.toolbar
  position: absolute
  white-space: no-wrap
  right: 0 !important
  top: 0 !important
  padding: rem(5px) 0
  opacity: 1 !important
  background: rgba($syntax-hl-bg-color, 0.8)
  font-family: $primary-font-family
  pointer-events: all
  user-select: none

.toolbar-item
  a
    font-size: large !important
    border-radius: 0px !important
    background: none !important

[data-syntax-hl-show-line-number]
  pre
    padding-left: 3.8em
    counter-reset: linenumber

  pre > code
    position: relative

  .line-numbers-rows
    position: absolute
    pointer-events: none
    top: 0
    font-size: 100%
    left: -3.8em
    width: 3em
    letter-spacing: -1px
    user-select: none

    span
      pointer-events: none
      display: block
      counter-increment: linenumber

      &:before
        content: counter(linenumber)
        color: #ddd
        display: block
        padding-right: 0.8em
        text-align: right

.token.tab:not(:empty),
.token.cr,
.token.lf,
.token.space
	position: relative

.token.tab:not(:empty):before,
.token.cr:before,
.token.lf:before,
.token.space:before
	color: #808080
	opacity: 0.6
	position: absolute

.token.tab:not(:empty):before
	content: '\21E5'

.token.cr:before
	content: '\240D'

.token.crlf:before
	content: '\240D\240A'

.token.lf:before
	content: '\240A'

.token.space:before
	content: '\00B7'
