.page--problem_submit
  .col--status
    width: rem(150px)

  .col--submit-at
    width: rem(160px)
    text-align: center

  +mobile()
    .col--memory,
    .col--time
      display: none
