.tooltip

  &.drop-element
    pointer-events: none

  .drop-content
    transition: transform .1s ease-in-out
    background: #333
    color: #fff
    font-size: rem($font-size-small)
    padding: .7em 0.9em
    max-width: rem(250px)
    box-shadow: 1px 1px 4px rgba(#000, 0.3)
    line-height: 1
    position: relative

    &:before
      content: ''
      display: block
      position: absolute
      width: 0
      height: 0
      border: rem(6px) solid transparent
      pointer-events: none

  &.drop-after-open
    transition-delay: .4s

    .drop-content
      transition-delay: .4s

  // Transitions
  &.drop-element-attached-bottom.drop-element-attached-center .drop-content
    transform: scale(0.9) translate(0, 5px) translateZ(0)
  &.drop-element-attached-bottom.drop-element-attached-left .drop-content
    transform: scale(0.9) translate(5px, 5px) translateZ(0)
  &.drop-element-attached-bottom.drop-element-attached-right .drop-content
    transform: scale(0.9) translate(-5px, 5px) translateZ(0)
  &.drop-element-attached-top.drop-element-attached-center .drop-content
    transform: scale(0.9) translate(0, -5px) translateZ(0)
  &.drop-element-attached-top.drop-element-attached-left .drop-content
    transform: scale(0.9) translate(5px, -5px) translateZ(0)
  &.drop-element-attached-top.drop-element-attached-right .drop-content
    transform: scale(0.9) translate(-5px, -5px) translateZ(0)
  &.drop-after-open .drop-content
    transform: scale(1) translateZ(0) !important

  // Triangle Borders
  &.drop-element-attached-bottom .drop-content
    bottom: rem(10px)
    &:before
      border-top-color: #333
  &.drop-element-attached-top .drop-content
    top: rem(10px)
    &:before
      border-bottom-color: #333

  // Triangle Positions
  &.drop-element-attached-bottom .drop-content:before
    bottom: rem(-12px)
  &.drop-element-attached-top .drop-content:before
    top: rem(-12px)
  &.drop-element-attached-center .drop-content:before
    margin-left: rem(-6px)
    left: 50%
  &.drop-element-attached-left .drop-content:before
    left: 0
  &.drop-element-attached-right .drop-content:before
    right: 0
