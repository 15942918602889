@import './var.inc.styl'

.button
  &:not(.codicon)
    background-color: lighten($default-color, 50%)
    border: 1px solid $default-color
    transition: background-color .2s, box-shadow .5s
    transition-timing-function: ease-out-sine

    &, &:visited, &:hover
      &, .typo &
        color: $text-1-color

    &:not(.disabled)
      &:hover, &:focus
        background-color: darken($default-color, 5%)
        border-color: darken($default-color, 5%)
        box-shadow: 0 4px 15px rgba(darken($default-color, 10%), 0.5)

      &:focus
        border-color: $input-focus-color

    &.rounded
      border-radius: rem($form-control-height / 2)

    &.primary
      border-color: $primary-color
      background-color: $primary-color

      &, &:visited, &:hover
        &, .typo &
          color: #FFF

      &:not(.disabled)
        &:hover
          background-color: lighten(saturate($primary-color, 30%), 10%)
          border-color: saturate($primary-color, 70%)
          box-shadow: 0 4px 15px rgba(saturate($primary-color, 70%), 0.3)

    &.disabled
      cursor: default
      opacity: 0.5

.button.inverse
  line-height: rem($form-control-height - 4)
  border: 2px solid #FFF
  background: none

  &, &:visited
    &, .typo &
      color: #FFF

  &:not(.disabled)
    &:hover, &:focus
      border-color: $immersive-primary-color
      background: none
      box-shadow: none
      &, .typo &
        color: $immersive-primary-color
