.scratchpad__data-input
  border: 0
  border-left: 3px solid #E0E0E0
  background: #F8F8F8
  padding: rem(8px 4px)
  font-family: $code-font-family
  font-size: rem($font-size-secondary)
  outline: 0
  resize: none
  color: #666
  height: 100%
  width: 100%
  transition: background-color .2s linear, border-color .2s linear

  &:focus
    background: #FFF
    border-color: lighten($primary-color, 70%)
