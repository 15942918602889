@import '~vj/components/tab/var.inc.styl'

.page--user_detail

  .profile-header
    position: relative
    background-position: center center
    background-size: cover
    min-height: rem(270px)
    padding-bottom: rem($tab-item-height + $tab-item-shadow-size + 20px)

    +mobile()
      padding-bottom: rem($tab-item-height-mobile)

  .profile-header__content
    position: relative
    padding: rem($section-gap-v) rem($section-gap-h) 0

    .media__left
      padding-right: rem(30px)

    +mobile()
      .media, .media__left, .media__body
        display: block

      .media__left
        text-align: center

        img
          display: inline-block

      .media__body
        margin-top: rem(20px)

  .profile-header__contact-bar
    position: absolute
    right: rem($section-gap-h + 5px)
    top: rem($section-gap-v + 20px)
    color: #FFF
    text-align: right

    +below(rupture.hd-cutoff)
      position: relative
      right: 0
      top: 0
      text-align: left
      margin: rem(15px 0)

  .profile-header__contact-item
    vertical-align: top
    color: rgba(#FFF, 0.8)
    margin-left: rem(10px)

    +below(rupture.hd-cutoff)
      margin-left: 0
      margin-right: rem(15px)

    &:hover
      color: rgba(#FFF, 1)
      text-decoration: none

    &.badge--su
      background-color: rgba(#FFF, 0.8) !important
      color: rgba(#000, 0.8) !important

    &.badge--mod
      background-color: rgba(#FFF, 0.8) !important
      color: rgba(#000, 0.8) !important

  .profile-header__main
    h1
      line-height: 40px
      color: #FFF

    p
      line-height: 30px
      color: #FFF

    small
      color: rgba(#FFF, 0.8)

  .profile-content
    position: relative
    z-index: 2
    margin-top: rem(-($tab-item-height + $tab-item-shadow-size))

    +mobile()
      margin-top: rem(-($tab-item-height-mobile))

    .section__tabs
      padding-top: rem($tab-item-height + $tab-item-shadow-size)

      +mobile()
        padding-top: rem($tab-item-height-mobile)
  #rating-placeholder
    width: auto
    height: 600px