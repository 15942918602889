.page--home_security

  .sessionlist
    font-size: rem($font-size-secondary)

  .sessionlist__icon
    font-size: rem(30px)
    color: #888
    margin: rem(0 20px)

  .sessionlist__item
    margin: rem(10px 0)
    padding: rem(10px 0)
    border-bottom: 1px solid #DDD

    input
      margin: 0

  .sessionlist__current-session
    line-height: rem($form-control-height)
    color: green

  +mobile()
    .sessionlist__item
      .media__left
        display: none

      .media__body, .media__right
        display: block
        padding: 0
        margin: 0
